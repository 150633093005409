import React from "react";
import { Card,  } from "@nextui-org/react";
import But from "../Components/button";

export default function Accueil() {

  return (
    < div
      style={{
        
        display: "flex",
        // justifyContent: "center", // Centre horizontalement
        alignItems: "center", // Centre verticalement
        flexDirection: "column", // Pour centrer le contenu verticalement
        position: "relative",

      }}

    >
       <Card
        className="py-4 centered-content"
        style={{
          width: "450px",
          height: "450px",
          backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/wheeloffortune--boa.appspot.com/o/back.jpeg?alt=media&token=06418cc3-b6d1-414e-988e-f02e55587364")', // Définissez l'image de fond ici
          backgroundSize: 'cover', // Ajustez la taille de l'image de fond selon vos besoins
          backgroundPosition: 'center', // Centrez l'image de fond horizontalement et verticalement




        }}
        isBlurred

      >


      </Card>
      <But />
      
    </div>
    

  );
}